import * as i0 from '@angular/core';
import { Injectable, NgModule } from '@angular/core';
import { facadeFactory, provideDefaultConfigFactory } from '@spartacus/core';
import { CART_BASE_FEATURE } from '@spartacus/cart/base/root';

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const CART_WISH_LIST_FEATURE = 'cartWishList';
const CART_WISH_LIST_CORE_FEATURE = 'cartWishListCore';
const ADD_TO_WISHLIST_FEATURE = 'addToWishList';

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
class WishListFacade {
  static {
    this.ɵfac = function WishListFacade_Factory(t) {
      return new (t || WishListFacade)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: WishListFacade,
      factory: () => (() => facadeFactory({
        facade: WishListFacade,
        feature: CART_WISH_LIST_CORE_FEATURE,
        methods: ['createWishList', 'getWishList', 'loadWishList', 'addEntry', 'removeEntry', 'getWishListLoading'],
        async: true
      }))(),
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WishListFacade, [{
    type: Injectable,
    args: [{
      providedIn: 'root',
      useFactory: () => facadeFactory({
        facade: WishListFacade,
        feature: CART_WISH_LIST_CORE_FEATURE,
        methods: ['createWishList', 'getWishList', 'loadWishList', 'addEntry', 'removeEntry', 'getWishListLoading'],
        async: true
      })
    }]
  }], null, null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
function defaultCartWishListComponentsConfig() {
  const config = {
    featureModules: {
      [CART_WISH_LIST_FEATURE]: {
        cmsComponents: ['WishListComponent'],
        dependencies: [CART_BASE_FEATURE]
      },
      [ADD_TO_WISHLIST_FEATURE]: {
        cmsComponents: ['AddToWishListComponent']
      },
      // by default core is bundled together with components
      [CART_WISH_LIST_CORE_FEATURE]: CART_WISH_LIST_FEATURE
    }
  };
  return config;
}
class WishListRootModule {
  static {
    this.ɵfac = function WishListRootModule_Factory(t) {
      return new (t || WishListRootModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: WishListRootModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [provideDefaultConfigFactory(defaultCartWishListComponentsConfig)]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WishListRootModule, [{
    type: NgModule,
    args: [{
      providers: [provideDefaultConfigFactory(defaultCartWishListComponentsConfig)]
    }]
  }], null, null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/**
 * Generated bundle index. Do not edit.
 */

export { ADD_TO_WISHLIST_FEATURE, CART_WISH_LIST_CORE_FEATURE, CART_WISH_LIST_FEATURE, WishListFacade, WishListRootModule, defaultCartWishListComponentsConfig };
